import React, { useState } from 'react'
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/edusmarty.jpg";
import { Modal, Button } from 'react-bootstrap'
import HubspotContactForm from './hubspot/HubspotContactForm';

const CoursesPage = () => {
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details__content">
              <div className="course-details__top">
                <div className="course-details__top-left">
                  <h2 className="course-details__title">Edusmarty Student Kit</h2>
                </div>
                <div className="course-details__top-right">
                  <a className="course-one__category">
                    For young learners
                  </a>
                </div>
              </div>
              <div className="course-one__image">
                <img src={image} alt=""  width={100}/>
              </div>
              <div className="tab-content course-details__tab-content ">
                <div
                  className="tab-pane show active  animated fadeInUp"
                  role="tabpanel"
                  id="overview"
                >
                  <p className="course-details__tab-text"> Looking for a comprehensive kit to provide your child with the best early childhood education from your home? Look no further, as EduSmarty is here to help you!  </p>
                  <br/>
                  <h3>
                  Why Choose EduSmarty?
                  </h3>
                  <ul>
                                    <li>
                                        Holistic curriculum based on National Council of Education Research and Training (NCERT) guidelines enhanced with the latest, proven international practices.
                                    </li>
                                    <li>
                                        Programme structure designed by practising early childhood educators with vast experience.
                                    </li>
                                    <li>
                                        Training with a strong focus to develop your child’s thought processes, conversational skills, problem-solving abilities, cognitive capacities and overall intellectual development.
                                    </li>
                                    <li>
                                        Innovative, yet unfussy Do-it-Yourself (DIY) activities that will make your child learn while they play.
                                    </li>
                                    <li>
                                        You’ll make your child’s unproductive screen-time into an engaging, educational and a wholesome bonding experience!
                                    </li>
                                    <li>
                                        Monthly guidance sessions, for parents.
                                    </li>
                                    <li>
                                        Annual subscriptions are also available at affordable prices.
                                    </li>
                                    <li>
                                        Developmentally-appropriate EduSmarty kits are available for following age groups
                                        <ul>
                                            <li>
                                                Play Group, for children between 2 to 3 years of age.
                                            </li>
                                            <li>
                                                Nursery, for children between 3 to 4 years of age.
                                            </li>
                                            <li>
                                                Junior KG, for children between 4 to 5 years of age.
                                            </li>
                                            <li>
                                                Senior KG, for children between 5 to 6 years of age.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                  For further assistance, we are just a phone-call away!
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="course-details__price">
              <p className="course-details__price-text">
                Talk to our executive and enroll now!{' '}
              </p>
              <Button
                onClick={handleShow}
                className="thm-btn course-details__price-btn"
                variant="default"
              >
                Enquire Now
              </Button>
            </div>
          </div>
          <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm/>
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
